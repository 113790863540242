<div class="row">
  <div class="col" style="justify-content: center;align-items: center;display: flex; margin-left: 10px;">
    <img
      style=""
      src="g/images/dfs-logo-white-small.png"
      alt="DFS logo"
    />
  </div>
  <div class="col" style="margin-left: 5px;margin-right: 10px;">
    <img
      src="g/images/dfs-shop-banner-01-small.jpg"
      alt="Inside DFS Shop"
      class="banner"
    />
  </div>
</div>

<style>
.row {
    display: flex;
}

img {
  object-fit: contain;
    width: 100%;
}
</style>
