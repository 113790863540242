<script>
  import { _ } from "svelte-i18n";
  import { contact_email, contact_message } from "../stores";

  import { color_aussen, nice_red, nice_orange } from "../style";

  let form = null;
  let el_email = null;
  let el_message = null;

  let el_toast_success = null;
  let el_toast_error = null;

  $: toast_error_msg_text = ""

  async function doPost(body) {
    const res = await fetch("/api/sendmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (res.ok) {
      let json = res.json();
      if (json.error) {
        throw json.error;
      } else {
        return json;
      }
    } else {
      throw `${$_("error")}: ${res.status}`;
    }
  }

  function on_email_changed(e) {
    $contact_email = e.target.value;
  }

  function on_message_changed(e) {
    $contact_message = e.target.value;
  }

  async function process_form() {
    if (form.checkValidity()) {
      try {
        await doPost({
          client_mail: $contact_email,
          message: $contact_message,
        });
        el_toast_success.style.display = 'block'
        // todo remove it after interval
        $contact_email = "";
        $contact_message = "";
        el_email.value = "";
        el_message.value = "";
      } catch (e) {
        el_toast_error.style.display = 'block'
        toast_error_msg_text = e
      }
      setTimeout(() => {
        el_toast_success.style.display = 'none'
        el_toast_error.style.display = 'none'
      }, 5000);
    } else {
      form.reportValidity();
    }
  }
</script>



<form
  bind:this={form}
  style={`--gs-nice-red: ${$nice_red};--gs-nice-orange: ${$nice_orange};`}
>

<div bind:this={el_toast_success} id="toast-msg-success">{$_("email_success_text")}</div>
<div bind:this={el_toast_error} id="toast-msg-error">{toast_error_msg_text}</div>


<input
    bind:this={el_email}
    on:change={on_email_changed}
    required
    value={$contact_email}
    position="fixed"
    type="email"
    id="contact-email"
    placeholder={$_("page.home.contact_card_email_placeholder")}
  /><br />

  <textarea
    bind:this={el_message}
    on:change={on_message_changed}
    value={$contact_message}
    position="fixed"
    placeholder={$_("page.home.contact_card_message_placeholder")}
    required
    rows="3"
  />
  <br />
  <br />

  <input
    type="button"
    style={`background-color: ${$nice_red};`}
    id="submit-button"
    expand="block"
    on:click={process_form}
    value={$_("page.home.contact_card_send_button_text")}
  />

</form>

<style>
  #submit-button {
    border: none;
    color: white;
    width: 100%;
    height: 50px;
    cursor: pointer;
    font-size: 24px;
  }

  form {
    margin: 10px 10px 0 10px;
  }

  textarea {
    background-color: #4a4a58;
    width: calc(100% - 15px);
    border: none;
    color: white;
    font-size: 18px;
    padding-left: 15px;
    padding-top: 15px;
    border: 1px solid black;
    margin-top: 10px;
    font-family: inherit;
  }

  #contact-email {
    background-color: #4a4a58;
    border: 1px solid black;
    color: white;
    font-size: 18px;
    width: 100%;
    padding-left: 15px;
    width: calc(100% - 15px);
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: inherit;
  }

  textarea:focus,
  #contact-email:focus {
    outline: none !important;
    border: 1px solid var(--gs-nice-orange);
    box-shadow: 0 0 10px var(--gs-nice-orange);
  }

  #toast-msg-success {
    display: none;
    border: 3px solid green;
    font-weight: bold;
    text-align: center;
    margin: 5px;
  }

  #toast-msg-error {
    display: none;
    border: 3px solid red;
    font-weight: bold;
    text-align: center;
    margin: 5px;
  }
</style>
